import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import LoginForm from '../components/LoginForm'
import Paywall from '../components/Paywall'
import isBrowser from '../utils/isBrowser'
import { withI18next, Link } from 'gatsby-plugin-i18n'

const MemberPage = ({ pageContext }) => {
  const { language } = pageContext
  const [logged, setLogged] = useState(
    isBrowser ? localStorage.getItem('isLoggedIn') : false,
  )

  const handleSuccess = (loginResult) => {
    if (loginResult) {
      isBrowser && localStorage.setItem('isLoggedIn', loginResult)
      setLogged(loginResult)
    }
  }

  return (
    <Layout language={language}>
      <main>
        <LightSection>
          <Container maxWidth="50rem">
            {logged && <Redirect to={`/${language}/workshops`} />}

            {!logged && (
              <Flex
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box sx={{ width: '45%' }}>
                  <Heading as="h2" mb={3}>
                    Not a member?
                  </Heading>

                  <Text sx={{ fontSize: '1.25rem' }}>
                    <Link to={'/sign-up'}>Sign up</Link> and get all access to
                    the videos and presentations for free
                  </Text>

                  <Text my={3}>or</Text>

                  <Paywall onSuccess={handleSuccess} />
                </Box>

                <Box sx={{ width: '45%' }}>
                  <Heading as="h2" mb={3}>
                    Already member?
                  </Heading>

                  <LoginForm onSuccess={handleSuccess} />
                </Box>
              </Flex>
            )}
          </Container>
        </LightSection>
      </main>
    </Layout>
  )
}

MemberPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
}

export default withI18next()(MemberPage)

export const pageQuery = graphql`
  query MemberTemplate($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "presentations"] }
      }
    ) {
      ...LocalLocaleFragment
    }
  }
`
